<template>
  <div id="login_page">
    <p class="tips">如遇问题请拨打客服热线：<span class="color1">400-821-5247</span></p>
    <div class="login_box">
      <p class="login_title">四季教育学习管理中心</p>
      <div class="form_box">
        <p>忘记密码</p>
        <el-form :model="loginForm"
                 :rules="ruleInline"
                 ref="loginForm"
                 class="login_form"
                 label-width="0px">
          <el-form-item prop="tel"
                        class="form-item">
            <el-input placeholder="填写手机号"
                      v-model="loginForm.tel"
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item  class="msgbox">
            <el-input type="text"
                      placeholder="图形验证码"
                      v-model="character"
                      clearable>
            </el-input>
            <canvas id="captchaCanvas" width="100" height="25"></canvas>
            <i class="el-icon-refresh-right"  @click.stop="loadpicCode"></i>
          </el-form-item>
          <el-form-item prop="yzm"
                        class="msgbox">
            <el-input type="text"
                      placeholder="短信验证码"
                      v-model="loginForm.yzm"
                      clearable>
            </el-input>
            <div class="sendmsg">
              <span>|&nbsp;</span>
              <el-button type="text"
                         :disabled="yamFlag"
                         @click.stop="sendMsg">{{yammsg}}</el-button>
            </div>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input type="password"
                      placeholder="设置新密码"
                      v-model="loginForm.pwd"
                      clearable>
            </el-input>
          </el-form-item>
          <div class="tipssm">
            <router-link to="/login"
                         class="linkbtn"> 返回登录</router-link>
          </div>
          <el-button class="submit_button"
                     type="primary"
                     @click="submitForm()"
                     :loading="loading">确认修改</el-button>
        </el-form>
      </div>
    </div>

  </div>

</template>

<script>
import md5 from 'js-md5';
import Cookies from "js-cookie";
import { telValidate, passwordValidate, smgValidate, checkTel,createCaptcha } from '../utils/util'
import { forget_pwd, send_sms,get_zifuchuan } from '@/api/user'
export default {
  data () {
    return {
      loginForm: {
        tel: '',
        pwd: '',
        yzm: ''
      },
      ruleInline: {
        tel: telValidate,
        pwd: passwordValidate,
        yzm: smgValidate,
      },
      loading: false,
      yammsg: '发送验证码',
      yamFlag: false,
      character:''
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.getzifuchuan()
    })
  },
  methods: {
    loadpicCode(){
      this.getzifuchuan()
    },
    //获取随机数
    async getzifuchuan () {
      let res = await get_zifuchuan();
      if (res.flag == '1') {
        var character = res.datainfo.character;
        createCaptcha('captchaCanvas',character);
      } else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          showClose: false,
          type: 'error',
        });
      }
    },
    //发送验证码
    async sendMsg () {
      if (!checkTel(this.loginForm.tel.replace(/\s+/g, ''))) {
        this.$message.error('请填写正确的手机号');
        return false
      } 
      if (this.character=='') {
        this.$message.error('请填写图形验证码');
        return false
      } 
      let res = await send_sms({ tel: this.loginForm.tel, type: 'forget',character:this.character })
      if (res.flag == '1') {
        this.$message({
          message: '短信发送成功',
          type: 'success'
        });
        this.timeCountDown();
      } else if (res.flag == '0') {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    //验证码倒计时
    timeCountDown () {
      var time = 60;
      this.timer = setInterval(() => {
        if (time == 0) {
          window.clearInterval(this.timer);
          this.yammsg = "发送验证码";
          this.yamFlag = false;
          return true;
        }
        this.yammsg = time + "S后再发送";
        time--;
        this.yamFlag = true;
        return false;
      }, 1000);
    },
    submitForm () {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.subfn()
        }
      })
    },
    async subfn () {
      let prames = {
        tel: this.loginForm.tel,
        pwd: md5(this.loginForm.pwd),
        yzm: this.loginForm.yzm,
      }
      this.loading = true;
      let res = await forget_pwd(prames)
      if (res.flag == '1') {
        this.$message({
          message: '修改成功，请登录',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push('/login')
        }, 1000)
        Cookies.remove('stu_username')
        Cookies.remove('stu_userpwd')
        Cookies.remove('stu_userId')
        this.loading = false
      } else if (res.flag == '0') {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        this.loading = false
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
          this.loading = false
        }
    }
  },
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "login.scss";
</style>
 <style lang="scss">
.login_box {
  .el-input__inner {
    border: 1px solid #6f72ff;
    color: #6f72ff;
    font-size: 16px;
    border-radius: 0;
  }
  .el-checkbox__label {
    color: #6f72ff;
    font-size: 16px;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #6f72ff;
    border-color: #6f72ff;
  }
  .el-checkbox__label,
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #6f72ff;
  }
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: rgba(111, 114, 255, 0.5);
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(111, 114, 255, 0.5);
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgba(111, 114, 255, 0.5);
  }
}
.msgbox{
  .el-form-item__content{
    display: flex!important;
    align-items: center;
  }
  .el-icon-refresh-right{
    font-size: 20px;
  }
}
</style>
